import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import { useMediaQuery } from "@material-ui/core"
import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner as StaticBanner } from "../../components/Banners/Kiberponedelnik"

import { Banner as RoundedDynamicBanner } from "../../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { Partners } from "../../components/PartnersNew"
import { NewFooter } from "../../components/NewFooter"
import { TwoChoice } from "../../components/twoChoice"

import { getPageData } from "../../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../../helpers/getDynamicBannerUrl"

import { PageData } from "../../interfaces/pageProps"

const bannerTitle = `До 36 месяцев рассрочки и до 5 000 ₽ кэшбэка`
const bannerDescription = `
  — Совершайте покупки в интернет-магазинах партнеров Халвы  
  <br />
  — Крутите Колесо Фортуны на промо-странице и получайте бонусы от Халвы 
`

const subTitlePartners = (
  <>
    БОЛЬШЕ подарков от Халвы и партнеров! Совершайте покупки в интернет-магазинах партнеров Халвы,
    крутите Колесо Фортуны на
    <a
      style={{ color: "#003791", textDecoration: "underline" }}
      href="https://postcard.sovcombank.ru"
      target="_blank"
      rel="noreferrer"
    >
      {" "}
      postcard.sovcombank.ru
    </a>{" "}
    и получайте промокоды на увеличение рассрочки по Акции до 36 месяцев или на кэшбэк до 5000
    рублей.
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()
  const tablet = useMediaQuery("(max-width: 960px)")

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  const timerDescription = (
    <strong>
      Крутите Колесо Фортуны с 22.01 <br />
      по 29.01 и получайте промокоды от <br />
      Халвы и партнеров
    </strong>
  )

  const firstBlockChoice = (
    <>Есть Халва – крутите Колесо Фортуны прямо сейчас! Получайте бонусы от Халвы и партнеров</>
  )

  const secondBlockChoice = (
    <>Нет «Халвы» - оформляй карту и после получения не забудь зарегистрироваться в акции</>
  )

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader
        showHint
        hasCTA
        countDownTime={countDownTime}
        hint="Крутите Колесо Фортуны с 22.01 по 29.01 и получайте промокоды от Халвы и партнеров."
        hintTitle={
          <>
            Участвуй в<br /> розыгрыше
          </>
        }
        background="#E93BF7"
      />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <StaticBanner
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          orderNum="1"
        />
      )}
      <FourAdvantagesPoints
        withTitle
        indent
        variant="newYear"
        title="Ваша выгода с Халвой:"
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle="Как принять участие в Акции"
        variant="kiberponedelnik"
        orderNum="3"
      />

      <FormPKW
        dataLayerName="shortPersonalForm"
        title="Оформите карту сейчас"
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="4"
        onlyPKWPage
        variant="kiberponedelnik"
        backgroundColor="#EDEAFF"
        imgTimerType="halva_card"
        timerDescription={timerDescription}
      />

      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="5"
      />
      <TwoChoice
        variant="kiberponedelnik"
        orderNum="6"
        textBlockTitle={firstBlockChoice}
        secondTextBlockTitle={secondBlockChoice}
        title={tablet && "Для участия в акции потребуется карта «Халва»"}
      />

      <NewFooter ligal={ligal} orderNum="7" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/kiberponedelnik/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
